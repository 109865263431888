import classNames from 'classnames';
import Layout from 'components/layout';
import SEO from 'components/seo';
import React from 'react';
import Style from 'styles/tokushoho.module.scss';

const Tokushoho = () => {
  return (
    <Layout>
      <SEO title='特定商取引法に基づく表記' path='tokushoho' />
      <div className={Style.container}>
        <div className={Style.ctIntro}>
          <div className={Style.ctIntro__inner}>
            <h1 className={Style.ctIntro__title}>特定商取引法に基づく表記</h1>
          </div>
        </div>
        <section className={Style.tokushoho__body}>
          <dl>
            <dt>事業者</dt>
            <dd>株式会社イーゼ</dd>
            <dt>運営責任者</dt>
            <dd>石井 幸治</dd>
            <dt>所在地</dt>
            <dd>〒101-0052東京都千代田区神田小川町3-6-1栄信ビル7階</dd>
            <dt>電話番号</dt>
            <dd>03-5244-5955</dd>
            <dt>メールアドレス</dt>
            <dd>comachi_operation@e-ize.jp</dd>
            <dt>ホームページ</dt>
            <dd>
              <a style={{ textDecoration: 'underline' }} href='https://e-ize.jp/' target='_blank'>
                https://e-ize.jp/
              </a>
            </dd>
            <dt>購入可能な範囲の制限</dt>
            <dd>特にございませんが、日本の国内法に準じてご利用していただきます。</dd>
            <dt>注文方法</dt>
            <dd>インターネット</dd>
          </dl>
          <h2 className={Style.tokushoho__body__title}>comachicartに関して</h2>
          <dl>
            <dt>■ サービス利用料等</dt>
            <dd>
              ・月額費用は0円となります。
              <br />
              ・システム利用料として、各販売ごとの売上（送料及び消費税を含む）に対して3%<sup>※1</sup>をいただきます。
              <br />
              ・クレジットカード決済を行う場合は、サービス利用料として、以下の費用が発生します。
              <ul>
                <li>・月額費用:0円</li>
                <li>・決済手数料:3.5%<sup>※2</sup></li>
                <li>・売上処理料:0円</li>
              </ul>
              <ul>
                <li>
                  <dt>支払い方法</dt>
                  <dd>クレジットカードまたは銀行振込でお支払いいただきます。</dd>
                </li>
                <li>
                  <dt>支払時期</dt>
                  <dd>請求書記載の所定の期日までにお支払いいただきます。</dd>
                </li>
              </ul>
              <p className={Style.tokushoho__body__annotation}>※1　システム利用料は税込価格となります。</p>
              <p className={Style.tokushoho__body__annotation}>※2　決済手数料は税抜価格となります。</p>
            </dd>
          </dl>
          <dl>
            <dt>■ 利用可能な決済手段</dt>
            <dd>
              ・クレジットカード：Visa、Mastercard、JCB、American Express
              <br />
              ・銀行振込
            </dd>

            <dt>■ 商品の引渡し時期</dt>
            <dd>サービスに登録後、即日ご利用いただけます。</dd>

            <dt>■ 返金・キャンセル</dt>
            <dd>返金、キャンセルは受け付けておりません。</dd>
          </dl>
        </section>
      </div>
    </Layout>
  );
};

export default Tokushoho;
